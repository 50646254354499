// Footer.js
import React from "react";

function Footer() {
  // get the year
  const year = new Date().getFullYear();

  return (
    <footer id="footer" className="section-bg">
      <div className="container" id="contact">
        <div className="row wow fadeInUp" data-wow-duration="500ms">
          <div className="col-xl-12">
            <div className="copyright text-center">
              <a href="mailto:info@phantafield.com">Contact us</a>
              <p>copyright © PhantaField {year} all right reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
