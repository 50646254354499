import './App.css';
import Footer from './sections/Footer';
import Logo from './Logo';
import Navbar from './sections/Navbar';
import Material from './sections/Material';
import Applications from './sections/Applications';
import Machine from './sections/Machine';
import {SmoothScroll} from './common/SmoothScroll';
import Order from './sections/Order';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Logo />
        <p
          className="block"
          style={{
            color: 'white',
            fontSize: '2rem',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '2rem',
          }}>
          Your mind amplified
        </p>
        <button
          class="btn btn-transparent page-scroll"
          onClick={e => SmoothScroll(e, 'material')}>
          Download the app
        </button>
      </header>
      <Navbar />

      <Footer />
    </div>
  );
}

export default App;
