import React, { useEffect, useRef } from "react";
import * as THREE from "three";

function Logo() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
      alpha: true,
      antialias: true,
    });

    const camera = new THREE.PerspectiveCamera(45, 2, 1, 1000);
    camera.position.set(0, 200, 400);
    camera.lookAt(new THREE.Vector3(0, 0, 0));

    const scene = new THREE.Scene();

    const geometry = new THREE.BoxGeometry(200, 200, 200);
    const wireframe = new THREE.EdgesGeometry(geometry);

    const material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
    });
    const line = new THREE.LineSegments(wireframe, material);

    scene.add(line);

    const vertices = [];
    const colors = []; // Add an array for colors
    var i = 0;
    var r = 100;
    for (i = 0; i < 500; i++) {
      var x = Math.random() - 0.5;
      var y = Math.random() - 0.5;
      var z = Math.random() - 0.5;
      if (x * x + y * y + z * z < 0.25) {
        vertices.push(x * 2 * r, y * 2 * r, z * 2 * r);
        // Assign a random color for each vertex
        // colors.push(187 / 255 - 2 * Math.abs(x), 37 / 255 - 2 * Math.abs(y), 40 / 255);
        colors.push(1, 1, 1);
      }
    }

    const pointGeometry = new THREE.BufferGeometry();
    pointGeometry.setAttribute("position", new THREE.Float32BufferAttribute(vertices, 3));
    pointGeometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3)); // Add color attribute

    const pointMaterial = new THREE.PointsMaterial({
      vertexColors: true, // Use vertex colors
      size: 5,
    });

    const points = new THREE.Points(pointGeometry, pointMaterial);

    scene.add(points);

    function animate(time) {
      time *= 0.001; // convert time to seconds
      line.rotation.x = time * 0;
      line.rotation.y = time * 1;
      points.rotation.y = time * 1;
      renderer.setSize(Math.min(window.innerWidth, 800), Math.min(window.innerWidth / 2, 400));
      renderer.render(scene, camera);
      const pixelRatio = window.devicePixelRatio;
      renderer.setPixelRatio(pixelRatio);
      requestAnimationFrame(animate);
    }

    requestAnimationFrame(animate);
  }, []);

  return <canvas ref={canvasRef} />;
}

export default Logo;
